<template>
  <v-dialog
    id="form_view"
    v-if="bActive"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="40%"
    v-model="bActive">
    <v-card
      :shaped="smUp"
      class="dialog_card_container">
      <!-- Start close icon -->
      <v-layout
        row
        justify-end>
        <v-btn
          style="margin-right: 10px"
          icon
          fab
          small
          color="grey"
          text
          @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-layout>
      <!-- End close icon -->
      <!-- Start dialog title -->
      <v-container
        style="margin-top: -30px">
        <v-layout
          row
          justify-center>
          <p
            v-text="dialog_title"
            class="dialog_title_text"
          />
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <!-- Start form containr -->
      <v-container>
        <v-layout
          row
          justify-center>
          <v-layout
            column
            :align-center="!smUp"
            style="max-width: 80%">
            <!-- Start name textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="name_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-text-field 
                v-model="operator_to_edit.sName"
                color="primary"
                :placeholder="name_text"
              />
            </v-layout>
            <!-- End name textfield -->
            <!-- Start phone textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="phone_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-text-field 
                v-model="operator_to_edit.sPhone"
                color="primary"
                :placeholder="phone_text"
              />
            </v-layout>
            <!-- End phone textfield -->
            <!-- Start mail textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="mail_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-text-field 
                v-model="operator_to_edit.sEmail"
                color="primary"
                :placeholder="mail_text"
              />
            </v-layout>
            <!-- End mail textfield -->
          </v-layout>
        </v-layout>
      </v-container>
      <!-- End form containr -->
      <v-container>
        <!-- Center button layout -->
        <v-layout
          row
          justify-center>
          <!-- Limit button layout extension -->
          <v-layout
            row
            style="max-width: 80%">
            <v-btn
              color="red"
              text
              @click="close">
              {{cancel_btn_txt}}
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn
              color="primary"
              dense
              :loading="load_edit_btn"
              :disabled="load_edit_btn"
              @click="confirm_edit">
              {{submit_btn_txt}}
            </v-btn>
          </v-layout>
          <!-- Limit button layout extension -->
        </v-layout>
        <!-- Center button layout  -->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    name_text: 'Name',
    company_text: 'Company',
    phone_text: 'Phone',
    mail_text: 'Email',
    password_text: 'Password',
    confirm_password_text: 'Confirm Password',
    submit_btn_txt: 'Save',
    cancel_btn_txt: 'Cancel',
    dialog_title: 'Edit Operator',
    load_edit_btn: false,
  }),
  props: {
    bActive: Boolean,
    operator_to_edit: Object
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm_edit () {
      this.load_edit_btn = true
      var user_update = {
        sName: this.operator_to_edit.sName,
        sEmail: this.operator_to_edit.sEmail,
        sPhone: this.operator_to_edit.sPhone,
      }
      var body = encode.encodeJSON(user_update)
      var userId = this.operator_to_edit._id
      db.put(`${uri}/v2/api/users/` + userId, body, {
        headers: {
          'Authorization': this.usr_token,
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
      .then(resp => {
        this.load_edit_btn = false
        const alert = {
          color: 'success',
          text: resp.data.message 
        }
        this.$store.commit('toggle_alert', alert)
        this.close()
        this.$emit('update')
      })
      .catch(err => {
         this.load_edit_btn = false
        const alert = {
          color: 'fail',
          text: err.response.data.message 
        }
        this.$store.commit('toggle_alert', alert)
      })
    },
  },
  computed: {
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    },
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    }
  }
}
</script>
<style scoped>
  .field_texts {
    margin-bottom: 0px;
    font-family: 'Open sans', sans-serif;
    color: 'black';
    font-size: 12px;
    letter-spacing: .1px;
  }
  .password_layout {
    width: 100%; 
    margin-bottom: 10px
  }
  .dialog_card_container {
    padding: 5%
  }
</style>